import React from "react"
import Layout from "./components/layout"

const ServicesNContact = () =>
  <Layout pageTitle="Services">
    <div className="content">
      <main>
        
        <br />
      </main>
    </div>
  </Layout>;

export default ServicesNContact;